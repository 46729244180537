import "./style.scss";

function Footer() {
  return (
    <div className="Footer d-flex justify-content-between align-items-center">
      <section className="name">
        <a
          href="https://www.linkedin.com/in/ivan-%C5%A1u%C5%A1nica-520b9a1a2/"
          target="_blank"
          rel="noreferrer"
        >
          <div className="linkedIn"></div>
        </a>

        <a
          href="https://www.flaticon.com/"
          title="ingredient icons"
          target="_blank"
          rel="noreferrer"
        >
          <div className="thanks"></div>
        </a>
      </section>

      <p>Ivan Šušnica</p>
      <p>Copyright @ 2023</p>
    </div>
  );
}

export default Footer;
