import timeIcon from "../../assets/icons/time.png";
import ingredientNumberIcon from "../../assets/icons/grocery.png";
import "./style.scss";
import Button from "../Button/Button";
import { VIEW_RECIPE } from "../../utilities/text";

function RecipeCard({ recipe }) {
  return (
    <div className="RecipeCard">
      <section className="image-section">
        <img src={recipe.image} alt="recipe" />
      </section>
      <section className="content-section">
        <h3>{recipe.recipeName}</h3>
        <hr />
        <div className="sub-content-section">
          <div className="logos">
            <div className="prep-time">
              <img src={timeIcon} alt="meal prep time" />
              <div className="recipe-number">{recipe.prepTime}</div>
            </div>
            <div className="ingredients-number">
              <img src={ingredientNumberIcon} alt="number of ingredients" />
              <div className="recipe-number">{recipe.ingredients.length}</div>
            </div>
            <div className={`category ${recipe.category}`}>
              {recipe.category}
            </div>
          </div>
          <div className="button-section">
            <Button buttonText={VIEW_RECIPE} path={`/recipes/${recipe.id}`} />
          </div>
        </div>
      </section>
    </div>
  );
}

export default RecipeCard;
