import loader from "../../assets/gif/noodle-loader.gif";
import "./style.scss";

const Loader = () => {
  return (
    <div className="Loader d-flex justify-content-center align-items-center">
      <img className="gif" src={loader} alt="food bowl" />
    </div>
  );
};

export default Loader;
