import { recipesURL } from "./constants";
import { mealPrepPicsURL } from "./constants";

export const fetchRecipes = (setFn) => {
  fetch(recipesURL)
    .then((res) => res.json())
    .then((data) => setFn(data));
};

export const fetchMealPrepPictures = (setFn) => {
  fetch(mealPrepPicsURL)
    .then((res) => res.json())
    .then((data) => setFn(data));
};
