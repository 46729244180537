import { HomePageTitle } from "../../utilities/constants";
import { Parallax } from "react-parallax";
import img from "../../assets/pictures/potato-pizza.jpg";
import bannerCardImage1 from "../../assets/pictures/set_table.jpg";
import bannerCardImage2 from "../../assets/pictures/bento_prep.jpg";

import { PATHS } from "../../routes/paths";
import "./style.scss";
import BannerCard from "../../components/BannerCard/BannerCard";
import {
  CARD_ONE_TEXT,
  CARD_TWO_TEXT,
  HERO_BANNER_TEXT,
  VIEW_RECIPE,
} from "../../utilities/text";
import useWindowWidth from "../../hooks/useWindowWidth";
import { useEffect, useState } from "react";

function HomePage() {
  const windowWidth = useWindowWidth();
  const [showTitle, setShowTitle] = useState(true);

  useEffect(() => {
    if (windowWidth < 500) {
      setShowTitle(false);
    } else {
      setShowTitle(true);
    }
  }, [windowWidth]);

  return (
    <div className="HomePage d-flex flex-column justify-content-center align-items-center">
      <section className="hero-banner">
        {showTitle && <h1 className="main-title">{HomePageTitle}</h1>}
        <div className="banner-text">{HERO_BANNER_TEXT}</div>
      </section>
      <BannerCard
        imageSrc={bannerCardImage1}
        cardTitle="20 recepata"
        cardText={CARD_ONE_TEXT}
        buttonPath={PATHS.allRecipes}
        buttonText={VIEW_RECIPE}
      />
      <Parallax className="banner-2" bgImage={img} strength={200}>
        <h2>
          Moj ulazak u svet kodiranja je zapravo počeo 2018. usled želje da
          razvijem sopstveni sajt sa receptima. 🙂
        </h2>
      </Parallax>
      <BannerCard
        imageSrc={bannerCardImage2}
        cardTitle="Meal prep ideje"
        cardText={CARD_TWO_TEXT}
        buttonPath={PATHS.mealPrep}
        buttonText={VIEW_RECIPE}
        reverse
      />
    </div>
  );
}

export default HomePage;
