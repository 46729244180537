import image from "../../assets/png/error-404-1.png";
import "./style.scss";

const PageNotFoundPage = () => {
  return (
    <section className="PageNotFoundPage d-flex flex-column justify-content-center align-items-center">
      <h3>Page Not Found</h3>
      <img src={image} alt="error message" />
    </section>
  );
};

export default PageNotFoundPage;
