import { Link } from "react-router-dom";

export const recipesURL =
  "https://636e3efdb567eed48ad7a075.mockapi.io/recipes/my-recipes";

export const mealPrepPicsURL =
  "https://636e3efdb567eed48ad7a075.mockapi.io/recipes/meal-prep-pics";

export const navBarContent = (
  <>
    <Link to="/">Početna</Link>
    <Link to="recipes">Recepti</Link>
    <Link to="meal-prep">Meal Prep</Link>
  </>
);

export const HomePageTitle = "Ivanov Kuvar 3 🌮";
