import { useEffect } from "react";
import { useParams } from "react-router-dom";
import "./styles.scss";

const RecipePage = ({ recipes }) => {
  const { id } = useParams();
  const chosenRecipe = recipes.find((recipe) => recipe.id === id);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="RecipePage">
      <h1 className="mb-3">{chosenRecipe.recipeName}</h1>
      <h3>Sastojci:</h3>
      <ul className="mb-4">
        {chosenRecipe.ingredients.map((ingredient) => {
          return <li>{ingredient}</li>;
        })}
      </ul>
      <h3>Priprema:</h3>
      <p className="mb-4">{chosenRecipe.preparation}</p>
      <img src={chosenRecipe.image} alt="prepared food" />
    </div>
  );
};

export default RecipePage;
