import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useWindowWidth from "../../hooks/useWindowWidth";
import "./style.scss";
import { navBarContent } from "../../utilities/constants";

function Header() {
  const windowWidth = useWindowWidth();
  const [showNavItems, setShowNavItems] = useState(true);
  const [showHamburgerContent, setShowHamburgerContent] = useState(false);

  const hamburgerHandler = () => {
    console.log("click");
    setShowHamburgerContent((prevState) => !prevState);
  };

  useEffect(() => {
    if (windowWidth < 480) {
      setShowNavItems(false);
    } else {
      setShowNavItems(true);
    }
  }, [windowWidth]);

  return (
    <div className="Header w-100 d-flex align-items-center">
      <div className="top w-100 d-flex justify-content-between align-items-center">
        <Link to="/" className="logo">
          Ivanov Kuvar 3 🌮
        </Link>
        {showNavItems ? (
          <div className="nav-items">{navBarContent}</div>
        ) : (
          <>
            <div
              className={`hamburger ${showHamburgerContent && "close"}`}
              onClick={hamburgerHandler}
            ></div>
            {showHamburgerContent && (
              <div className="hamburger-menu">
                <div className="hamburger-close"></div>
                <Link to="/" onClick={hamburgerHandler}>
                  Početna
                </Link>
                <Link to="recipes" onClick={hamburgerHandler}>
                  Recepti
                </Link>
                <Link to="meal-prep" onClick={hamburgerHandler}>
                  Meal Prep
                </Link>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default Header;
