import React from "react";
import { useEffect } from "react";

import Loader from "../../components/Loader/Loader";
import "./style.scss";

const MealPrepItem = React.lazy(() =>
  import("../../components/MealPrepItem/MealPrepItem")
);

const MealPrepPage = ({ mealPrepPics }) => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <section className="MealPrepPage d-flex flex-wrap justify-content-between">
        {mealPrepPics.map((e, i) => (
          <React.Suspense fallback={<Loader />} key={`suspense${e.id}`}>
            <MealPrepItem e={e} i={i} key={`mealprep${e.id}`} />
          </React.Suspense>
        ))}
      </section>
    </>
  );
};

export default MealPrepPage;
