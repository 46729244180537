import { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import HomePage from "./pages/HomePage/HomePage";
import RecipesPage from "./pages/RecipesPage/RecipesPage";
import RecipePage from "./pages/RecipePage/RecipePage";
import MealPrepPage from "./pages/MealPrepPage/MealPrepPage";
import PageNotFoundPage from "./pages/PageNotFoundPage/PageNotFound";
import { fetchMealPrepPictures, fetchRecipes } from "./utilities/http-requests";
import "./App.scss";

function App() {
  const [recipes, setRecipes] = useState([]);
  const [mealPrepPics, setMealPrepPics] = useState([]);
  const [showNavBar, setShowNavBar] = useState(false);

  useEffect(() => {
    fetchRecipes(setRecipes);
    fetchMealPrepPictures(setMealPrepPics);
  }, []);

  return (
    <BrowserRouter>
      <main className="App w-100 d-flex flex-column justify-content-between">
        <Header showNavBar={showNavBar} setShowNavBar={setShowNavBar} />
        <Routes>
          <Route
            path="/"
            element={<HomePage setShowNavBarTwo={setShowNavBar} />}
          />
          <Route path="/recipes" element={<RecipesPage recipes={recipes} />} />
          <Route
            path="/meal-prep"
            element={<MealPrepPage mealPrepPics={mealPrepPics} />}
          />
          <Route
            path="/recipes/:id"
            element={<RecipePage recipes={recipes} />}
          />

          <Route path="*" element={<PageNotFoundPage />} />
        </Routes>
        <Footer />
      </main>
    </BrowserRouter>
  );
}

export default App;
