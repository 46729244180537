export const VIEW_RECIPE = "Više";
export const CARD_ONE_TEXT = `Za trenutnu verziju kuvara izdvojio sam 20 recepata koji najčešće spremam. Ono što je zajedničko za 
sve jeste da su  vegetarijanski (dakle bez mesa ... i da, ribu takođe računamo kao meso 🙂). Uz neke od njih sam odrastao i pokušao 
da sačuvam u izvornoj formi (dakle gledamo na nešto što se jelo po selima/malim gradovima u Bačkoj tokom '90tih), dok sam druge sam
razvio u želji da i dalje jedem ukusnu hranu čak i ako ne jedem meso.`;
export const CARD_TWO_TEXT = `Nedeljom se obično upuštam u nešto što sam #tag-ovao na IG-u kao meal prep sunday. Sprema se pet obroka 
koji se nose na posao tokom radne nedelje za ručak. Ispostavilo se da je to jedini način da pojedem nešto kuvano i relativno zdravo. 😅 I dok nisam ispisao detaljne instrukcije za pojedinačne meal-prep-ove, kombinacija sa kojom obično idem jeste: neko
varivo (e.g. sočivo, leblebija, grašak i sl.), protein-boost (falafeli, veggie pljeske, sojine šnicle/komadići, jaja) i neko povrće 
dodatno da pokrijemo i vlakna. Nadam se da će neko moći da izvuče neku inspiraciju iz svih tih slika. 🤤`;
export const HERO_BANNER_TEXT = `Prilično rano u životu mi je bilo jasno da kuvanje nikada neće biti posao ... 
biće nešto što me čini srećnim. 🥰`;
