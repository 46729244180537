import { useEffect, useState } from "react";
import RecipeCard from "../../components/RecipeCard/RecipeCard";
import "./style.scss";

function RecipesPage({ recipes }) {
  const [category, setCategory] = useState("");
  const [categorizedRecipes, setCategorizedRecipes] = useState([]);

  const categoryHandler = (chosenCategory) => {
    setCategory(chosenCategory);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    setCategorizedRecipes(recipes.filter((e) => e.category === category));
  }, [category, recipes]);

  return (
    <div className="RecipesPage py-2 px-0">
      <div className="categories-bar">
        <div className="category svi" onClick={categoryHandler.bind(null, "")}>
          svi
        </div>
        <div
          className="category testo"
          onClick={categoryHandler.bind(null, "testo")}
        >
          testo
        </div>
        <div
          className="category slatko"
          onClick={categoryHandler.bind(null, "slatko")}
        >
          slatko
        </div>
        <div
          className="category variva"
          onClick={categoryHandler.bind(null, "variva")}
        >
          variva
        </div>
        <div
          className="category krompir"
          onClick={categoryHandler.bind(null, "krompir")}
        >
          krompir
        </div>
        <div
          className="category proteini"
          onClick={categoryHandler.bind(null, "proteini")}
        >
          proteini
        </div>
        <div
          className="category razno"
          onClick={categoryHandler.bind(null, "razno")}
        >
          razno
        </div>
      </div>
      <div className="RecipesPage py-2 px-0">
        {(category ? categorizedRecipes : recipes).map((e) => {
          return <RecipeCard recipe={e} key={e.id} />;
        })}
      </div>
    </div>
  );
}

export default RecipesPage;
