import { Link } from "react-router-dom";
import "./style.scss";

const Button = ({ path, buttonText, clickHandler }) => {
  return (
    <>
      {path ? (
        <Link to={path} className="Button">
          {buttonText}
        </Link>
      ) : (
        <button type="button" className="Button" onClick={clickHandler}>
          {buttonText}
        </button>
      )}
    </>
  );
};

export default Button;
