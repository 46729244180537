import Button from "../Button/Button";
import "./style.scss";

const BannerCard = ({
  imageSrc,
  cardTitle,
  cardText,
  buttonText,
  buttonPath,
  reverse,
}) => {
  return (
    <div className={`BannerCard ${reverse && "reverse"}`}>
      <img src={imageSrc} alt="recipe prepared" />
      <section className="banner-card-content">
        <h3>{cardTitle}</h3>
        <p>{cardText}</p>
        <Button path={buttonPath} buttonText={buttonText} />
      </section>
    </div>
  );
};

export default BannerCard;
